<script context="module">
	export async function load({ session, url: { pathname } }) {
		const isLogin = pathname.includes('/auth/login');
		const isReport = pathname.includes('report');
		const isJob = pathname.startsWith('/job');
		const userName = session.userName;
		const skipAuth = isLogin || isReport || isJob;

		if (!session.isLoggedIn && !skipAuth)
			return {
				status: 302,
				redirect: `/auth/login?redirect=${pathname}`
			};

		return {
			props: { skipAuth, userName }
		};
	}
</script>

<script>
	import '../app.css';
	import '/node_modules/flag-icons/css/flag-icons.min.css';
	import { account } from '$utils/appwrite-client';
	import { page, session } from '$app/stores';

	import * as Sentry from '@sentry/svelte';
	import { BrowserTracing } from '@sentry/tracing';
	import Tooltip from '$lib/Tooltip.svelte';

	Sentry.init({
		environment: import.meta.env.VITE_SENTRY_ENV,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: [new BrowserTracing()],
		tracesSampleRate: 0.3
	});

	export let skipAuth = false;
	export let userName;
</script>

<main class="bg-black min-h-screen w-auto">
	{#if !skipAuth}
		<nav class="bg-black fixed left-0 right-0 bottom-0 sm:relative z-50">
			<div class="px-4 md:px-10">
				<div class="flex items-center justify-between h-14">
					<div class="flex items-center gap-2">
						<a href="/">
							<svg
								class="fill-white w-6 h-6"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M21 8.77217L14.0208 1.79299C12.8492 0.621414 10.9497 0.621413 9.77817 1.79299L3 8.57116V23.0858H10V17.0858C10 15.9812 10.8954 15.0858 12 15.0858C13.1046 15.0858 14 15.9812 14 17.0858V23.0858H21V8.77217ZM11.1924 3.2072L5 9.39959V21.0858H8V17.0858C8 14.8767 9.79086 13.0858 12 13.0858C14.2091 13.0858 16 14.8767 16 17.0858V21.0858H19V9.6006L12.6066 3.2072C12.2161 2.81668 11.5829 2.81668 11.1924 3.2072Z"
								/>
							</svg>
						</a>

						<a
							href="/order/new"
							class={`p-2 bg-neutral-800 rounded-lg hover:bg-neutral-600`}
							class:bg-zinc-700={$page.url.pathname.includes('/order/new')}
							aria-current="page"
						>
							<Tooltip title="Create Order">
								<svg
									class="fill-white h-4 w-4"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44772 12.5523 4 12 4Z"
									/>
								</svg>
							</Tooltip>
						</a>

						<a class={`p-2 bg-neutral-800 rounded-lg hover:bg-neutral-600`} href="/planning">
							<Tooltip title="Planning">
								<svg
									class="fill-white w-4 h-4"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M20 4H4C3.44771 4 3 4.44772 3 5V19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19V5C21 4.44771 20.5523 4 20 4ZM4 2C2.34315 2 1 3.34315 1 5V19C1 20.6569 2.34315 22 4 22H20C21.6569 22 23 20.6569 23 19V5C23 3.34315 21.6569 2 20 2H4ZM6 7H8V9H6V7ZM11 7C10.4477 7 10 7.44772 10 8C10 8.55228 10.4477 9 11 9H17C17.5523 9 18 8.55228 18 8C18 7.44772 17.5523 7 17 7H11ZM8 11H6V13H8V11ZM10 12C10 11.4477 10.4477 11 11 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H11C10.4477 13 10 12.5523 10 12ZM8 15H6V17H8V15ZM10 16C10 15.4477 10.4477 15 11 15H17C17.5523 15 18 15.4477 18 16C18 16.5523 17.5523 17 17 17H11C10.4477 17 10 16.5523 10 16Z"
									/>
								</svg>
							</Tooltip>
						</a>

						<a
							href="/resources"
							class={`p-2 bg-neutral-800 rounded-lg hover:bg-neutral-600`}
							class:bg-zinc-700={$page.url.pathname.includes('/resources')}
							aria-current="page"
						>
							<Tooltip title="Resources">
								<svg
									class="fill-white w-4 h-4"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M20.2739 9.86883L16.8325 4.95392L18.4708 3.80676L21.9122 8.72167L20.2739 9.86883Z"
									/>
									<path
										d="M18.3901 12.4086L16.6694 9.95121L8.47783 15.687L10.1985 18.1444L8.56023 19.2916L3.97162 12.7383L5.60992 11.5912L7.33068 14.0487L15.5222 8.31291L13.8015 5.8554L15.4398 4.70825L20.0284 11.2615L18.3901 12.4086Z"
									/>
									<path
										d="M20.7651 7.08331L22.4034 5.93616L21.2562 4.29785L19.6179 5.445L20.7651 7.08331Z"
									/>
									<path
										d="M7.16753 19.046L3.72607 14.131L2.08777 15.2782L5.52923 20.1931L7.16753 19.046Z"
									/>
									<path
										d="M4.38208 18.5549L2.74377 19.702L1.59662 18.0637L3.23492 16.9166L4.38208 18.5549Z"
									/>
								</svg>
							</Tooltip>
						</a>

						<a
							href="/finance"
							class={`p-2 bg-neutral-800 rounded-lg hover:bg-neutral-600`}
							class:bg-zinc-700={$page.url.pathname.includes('/finance')}
							aria-current="page"
						>
							<Tooltip title="Finance">
								<svg
									class="fill-white w-4 h-4"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M22.775 8C22.9242 8.65461 23 9.32542 23 10H14V1C14.6746 1 15.3454 1.07584 16 1.22504C16.4923 1.33724 16.9754 1.49094 17.4442 1.68508C18.5361 2.13738 19.5282 2.80031 20.364 3.63604C21.1997 4.47177 21.8626 5.46392 22.3149 6.55585C22.5091 7.02455 22.6628 7.5077 22.775 8ZM20.7082 8C20.6397 7.77018 20.5593 7.54361 20.4672 7.32122C20.1154 6.47194 19.5998 5.70026 18.9497 5.05025C18.2997 4.40024 17.5281 3.88463 16.6788 3.53284C16.4564 3.44073 16.2298 3.36031 16 3.2918V8H20.7082Z"
										fill="currentColor"
									/>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M1 14C1 9.02944 5.02944 5 10 5C10.6746 5 11.3454 5.07584 12 5.22504V12H18.775C18.9242 12.6546 19 13.3254 19 14C19 18.9706 14.9706 23 10 23C5.02944 23 1 18.9706 1 14ZM16.8035 14H10V7.19648C6.24252 7.19648 3.19648 10.2425 3.19648 14C3.19648 17.7575 6.24252 20.8035 10 20.8035C13.7575 20.8035 16.8035 17.7575 16.8035 14Z"
										fill="currentColor"
									/>
								</svg>
							</Tooltip>
						</a>

						<a
							href="/rut"
							class={`text-white p-2 rounded-md text-xs font-medium bg-neutral-800 hover:bg-neutral-600`}
							class:bg-zinc-700={$page.url.pathname.includes('/rut')}
							aria-current="page">RUT</a
						>
					</div>

					<div class="block">
						<div class="ml-4 flex items-center md:ml-6">
							{#if !$session.isLoggedIn}
								<a href={'/auth/login'}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										class="h-6 w-6"
										fill="none"
										viewBox="0 0 24 24"
										stroke="white"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
										/>
									</svg></a
								>
							{:else}
								<form
									method="post"
									action="/auth/requests/logout"
									on:submit={async () => await account.deleteSession('current')}
								>
									<button
										type="submit"
										class="flex align-center gap-3 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
									>
										<p class="hidden sm:block">{userName ?? ''}</p>
										<!-- Heroicon name: logout -->
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="white"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
											/>
										</svg>
									</button>
								</form>
							{/if}
						</div>
					</div>
				</div>
			</div>
		</nav>

		<div class="self-start p-2 text-white">
			<slot />
		</div>
	{:else}
		<slot />
	{/if}
</main>
